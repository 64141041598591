const BARIUM_ENEMA_DA = `BARIUM ENEMA

Barium flows freely  from rectum to caecum with reflux into the terminal ileum. The colonic mucosa appear normal. There is no colonic mass, stricture or diverticulum seen. The terminal ileum appear normal.

IMPRESSION:

Normal examination.

`

export default BARIUM_ENEMA_DA;
