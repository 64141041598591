const US_BOTH_BREASTS = `ULTRASOUND BOTH BREASTS
 
Normal fibroglandular tissue appearance.
No focal solid or cystic lesions are seen in both breasts. No dilated ducts noted.
There is a small well-circumscribed hypoechoic nodule with smooth margin at 10 o'clock position left breast measuring 0.8 x 0.4 mm.
No other focal solid or cystic nodule seen in both breasts. No dilated ducts noted.
No significantly enlarged or suspicious axillary nodes seen.
 
Right:
Normal fibroglandular breast tissue.
No focal solid or cystic lesion seen.
No dilated ducts seen.

There is a solid/cystic hypoechoic lesion at   . The margin is regular and well-circumscribed. No posterior shadowing is seen. No echogenic foci seen within.
A small cyst seen at
No dilated ducts seen.
 
Left:
 


A few reactive nodes seen at right and left axilla largest on the
 
IMPRESSION:
Small nodule left breast appears benign, likely a small fibroadenoma (BIRADS 2).
Suggest follow up scan in 6 months. Please correlate clinically.
`

export default US_BOTH_BREASTS;
