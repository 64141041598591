const MRI_PERINEUM_DA = `MRI PERINEUM

4 mm axial T1, T2 and STIR and  4 mm coronal and sagittal STIR were acquired  through the perineum.

Initial vitamen E marker was placed in the natal cleft.

There is no evidence of  perianal abscess or inflammation seen in the perianal or ischiorectal  fossa. Urinary bladder , seminal vesicle and  prostate gland appear normal. 

IMPRESSION:

No evidence of residual or recurrent perianal abscess detected.
`

export default MRI_PERINEUM_DA;
