const US_LEFT_SHOULDER = `ULTRASOUND LEFT SHOULDER
 
The rotator cuff tendons are intact. No tears are seen.
No impingement noted on the abduction test.
 
The LHBT is intact.
Noted degenerative changes of acromioclavicular joints.
 
IMPRESSION:
No rotator cuff tear or impingement.
Degenerative changes of AC joint.
Please correlate clinically.
`

export default US_LEFT_SHOULDER;
