const US_PELVIS_DA = `ULTRASOUND PELVIS: 

The urinary bladder appear normal. Uterus is anteverted and normal in size and appearance. No intrauterine mass lesion seen. There is no mass or cystic lesion or free fluid seen in the right or left adnexa or pelvis. Both kidneys appear normal. 

IMPRESSION:  

Normal examination.

`

export default US_PELVIS_DA;
