import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import MD5 from "crypto-js/md5";
import jsPDF from "jspdf";
import "../CreateReport/OpenSans-Regular-normal";
import WallpaperCard from "../../assets/images/wallpaper-card.png";
import PadiLogoTransparent from "../../assets/images/padi-logo-transparent.png"; //tukar share card - logo
import PadiLogoTransparent1 from "../../assets/images/padi-logo-transparent1.png";
import { QRCode } from "react-qrcode-logo";
import { Button } from "@material-ui/core";
import logo from "../../assets/images/logo-2.jpg";

export default class ModalCardShareButtonDownload extends Component {
  state = {
    doc: null,
  };

  constructor(props) {
    super(props);
    const IDs = this.props.StudyInstanceUID.split(".");
    const slicedPwd = MD5(IDs.pop()).toString();
    this.PWD = slicedPwd.slice(slicedPwd.length - 8);
    this.DownloadLink = `${window.location.protocol}//${window.location.host}/external/download/`; //changes made by rishabh (25-05-2022)
    this.ViewLink = `${window.location.protocol}//${window.location.host}/external/view/`;
  }

  generateCard = async () => {
    // console.log(this.props)
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: [248, 180],
      precision: 1,
      compress: true,
      title: this.props.pname,
    });
    await this.addImageToDoc(doc, WallpaperCard);
    await this.addImageToDoc(doc, PadiLogoTransparent1, 105, 35, 125, 35); ////tukar share card - logo size(Right left), 10(up down), 125(scale width), 85(scaling height)
    const el = document.getElementById("testqr");
    const canvas = el.children[0];
    const dataURL = canvas.toDataURL("image/jpeg,1.0");
    doc.addImage(dataURL, "JPEG", 10, 10, 85, 85);

    this.addWrappedText({
      text: `${this.props.pname}`
      .replaceAll("  ", " ")
      .replaceAll("^", " "), // Put a really long string here
      
      textWidth: 400,
      doc,
      // Optional
      fontSize: "8",
      fontType: "OpenSans-Regular",
      lineSpacing: 7, // Space between lines
      xPosition: 70, // Text offset from left of document
      initialYPosition: 110, // Initial offset from top of document; set based on prior objects in document
      pageWrapInitialYPosition: 10, // Initial offset from top of document when page-wrapping
    });

    this.addWrappedText({
      text: "(" + `${this.props.pid}` + ")"
        , // Put a really long string here
      textWidth: 280,
      doc,
      // Optional
      fontSize: "5",
      fontType: "OpenSans-Regular",
      lineSpacing: 7, // Space between lines
      xPosition: 50, // Text offset from left of document
      initialYPosition: 125, // Initial offset from top of document; set based on prior objects in document
      pageWrapInitialYPosition: 10, // Initial offset from top of document when page-wrapping
    });

    this.addWrappedText({
      text: "Access Code: " + this.PWD, // Put a really long string here
      textWidth: 200,
      doc,
      // Optional
      fontSize: "10",
      fontType: "OpenSans-Regular",
      lineSpacing: 7, // Space between lines
      xPosition: 70, // Text offset from left of document
      initialYPosition: 138, // Initial offset from top of document; set based on prior objects in document
      pageWrapInitialYPosition: 10, // Initial offset from top of document when page-wrapping
    });
    this.addWrappedText({
      url: this.ViewLink + this.props.StudyInstanceUID,
      text: "Click Here To View  ",
      textWidth: 240,
      doc,

      // Optional
      fontSize: "12",
      fontType: "bold",
      lineSpacing: 7, // Space between lines
      xPosition: 70, // Text offset from left of document
      initialYPosition: 155, // Initial offset from top of document; set based on prior objects in document
    });

    this.addWrappedText({
      url: this.DownloadLink + this.props.StudyInstanceUID,
      text: "Click Here To Download ",
      textWidth: 240,
      doc,

      // Optional
      fontSize: "12",
      fontType: "bold",
      lineSpacing: 7, // Space between lines
      xPosition: 60, // Text offset from left of document
      initialYPosition: 170, // Initial offset from top of document; set based on prior objects in document
    });
    this.setState({ doc });
  };

  addImageToDoc = async (
    doc,
    src,
    posx = 0,
    posy = 0,
    width = 250,
    height = 250
  ) => {
    const img = new Image();
    img.crossOrigin = "";
    img.src = src;
    await img.decode();
    doc.addImage(img, "png", posx, posy, width, height, "", "FAST");
  };

  addWrappedText = ({
    url,
    text,
    textWidth,
    doc,
    fontSize = 14,
    fontType = "normal",
    lineSpacing = 7,
    xPosition = 10,
    initialYPosition = 10,
    pageWrapInitialYPosition = 10,
    fontWeight,
  }) => {
    const textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
    const pageWidth = doc.internal.pageSize.width;
    if (fontType === "bold") doc.setFont(fontType, "bold");
    else doc.setFont(fontType);
    doc.setFontSize(fontSize);
    let cursorY = initialYPosition;
    doc.text(pageWidth / 2, cursorY, textLines, { align: "center" });
    //doc.text(xPosition, cursorY, textLines);
    if (url) {
      doc.link(0, cursorY - 10, textWidth, 20, { url });
    }
  };

  downloadDoc = async () => {
    await this.state.doc.save(`${this.props.pname}-${this.props.pid}`);
  };

  render = () => {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onClick={(e) => e.stopPropagation()}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-dark">
          <Row style={{ textAlign: "center" }}>
            <Col id="testqr" style={{ display: "none" }}>
              <QRCode
                size={300}
                logoWidth={200}
                logoHeight={50}
                logoOpacity={0.6}
                value={this.ViewLink + this.props.StudyInstanceUID}
                logoImage={logo}
              />
            </Col>
            {this.state.doc !== null && (
              <div>
                <h3>Preview</h3>
                <iframe
                  style={{ border: "none", height: 260, width: "100%" }}
                  src={this.state.doc.output("bloburl")}
                ></iframe>
              </div>
            )}
            <br />
            <Button onClick={this.generateCard}>Generate PDF card</Button>
            <br />
            <Button
              disabled={this.state.doc === null}
              onClick={this.downloadDoc}
            >
              Download PDF card
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };
}
