const MRI_LUMBAR_SPINE = `MRI LUMBAR SPINE
 
Findings:
Straightening of / Normal lumbar lordosis, no listhesis.
Normal vertebral body height, no fracture seen.
Lumbar scoliosis concave to the right.
Spondylotic changes seen along the lumbar spine.
A small well-defined lesion seen in the body of L4 measures 9 x 8 mm. The lesion returns a high signal in T1, T2 and STIR. No perilesional marrow edema. No vertebral body fracture is seen. No other focal vertebral lesions seen.
 
Desiccated changes involving all of lumbar intervertebral discs.
 
At L1/L2, L2/L3, L3/L4:
Mild posterior disc protrusion causing mild narrowing of right and left neuroforamina.
No impingement of the traversing or exiting nerves seen. No canal stenosis.
 
At L3/L4:
Mild posterolateral disc protrusion causing mild narrowing of right and left neuroforamina.
No impingement of the traversing or exiting nerves seen. No significant canal stenosis.
 
At L4/L5 and L5/S1:
Posterior disc protrusion slightly to the left causing significant narrowing of left neuroforamina and impingement of the exiting left L4 and L5 nerves. There is mild narrowing of right neuroforamina but no impingement of exiting right L4 or L5 nerves. No significant canal stenosis.
 
Normal conus medullaris and cauda equina. Normal facet joints and ligaments.

Noted facet joints hypertrophy and ligamentum flavum thickening at     causing canal stenosis.
 
IMPRESSION:
1. Lumbar scoliosis and spondylosis changes.
2. Small hemangioma in the body of L4 vertebra.
3. L4/L5 and L5/S1 posterior disc protrusion slightly to the left causing impingement of the exiting left L4 and L5 nerves.
Please correlate clinically.
`

export default MRI_LUMBAR_SPINE;
