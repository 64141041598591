const US_TESTIS_BILATERAL = `US TESTIS BILATERAL
 
Findings:
Both testis are normal in echotexture and vascularity.
Right testis measures 1.4 x 2.7 x 3.0cm
Left testis measures 1.6 x 2.4 x 4.0cm.
Both epidydimis are normal.
No focal solid testicular lesions bilaterally.
No hydrocele bilaterally.

Impression:
No significant abnormality.

`

export default US_TESTIS_BILATERAL;
