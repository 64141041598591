const US_BOTH_HIPS = `Right hip:
The alpha angle (α) between iliac wing and acetabular roof measures _ degree and beta (β)angle between labral and iliac wing measures _ degree.
 
Left hip:
The alpha angle (α) between iliac wing and acetabular roof measures _ degree and beta (β)angle between labral and iliac wing measures _ degree.
 
IMPRESSION:
Following Graf's technique and classification, there is no evidence of hips dysplasia. 
`

export default US_BOTH_HIPS;
