const US_PELVIS = `ULTRASOUND PELVIS

Normal urinary bladder. The wall is not thickened. No stone or debris within.
Uterus is partially obscured, no obvious focal lesion seen.
Uterus is anteverted / retroverted. No focal lesion seen. 
Both ovaries appear normal.
Both kidneys appear normal.

IMPRESSION: Normal examination.
`

export default US_PELVIS;
