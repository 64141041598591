const CT_COLONOSCOPY_DA = `CT COLONOSCOPY: 

0.5 x 64 MDCT of the colon was done in the supine and prompt position  following Insufflation of air. 

The colonic mucosa appear normal. There is no colonic polyp, mass, stricture or diverticulum seen.

IMPRESSION: 

Normal examination. No colonic abnormality detected.

`

export default CT_COLONOSCOPY_DA;
