const US_DOPPLER_CAROTID = `ULTRASOUND DOPPLER CAROTID
 
Right Side:
Mild intimal thickening along the common carotid artery.
Normal PSV and EDV at the right CCA and ICA.
The ICA/CCA ratio is within the normal limit (less than 2.0).
 
Left Side:
Mild intimal thickening along the common carotid artery.
Normal PSV and EDV at the right CCA and ICA.
The ICA/CCA ratio is within the normal limit (less than 2.0).
 
Poor visualisation and Doppler signal from right and left vertebral arteries.
 
IMPRESSION: 
Mild intimal thickening along both right and left CCA.
Normal ICA/CCA ratio in both carotid arteries.
Please correlate clinically.
`

export default US_DOPPLER_CAROTID;
