const MRI_PELVIMETRY_DA = `MR PELVIMETRY 

Pelvic measurements are as follows:

AP inlet			- 11.6 cm

Mid pelvic cavity	- 12.3 cm

AP outlet		- 10.8 cm
`

export default MRI_PELVIMETRY_DA;
