const US_CRANIUM = `ULTRASOUND CRANIUM
 
The visualized brain parenchyma appears symmetrical and normal bilaterally.
Midline structures are normal. No midline shift or hydrocephalus.
The posterior fossa appear normal.
 
Impression:
No focal intracranial lesion or mass effect.
Normal ventricles size. No hydrocephalus.
No structural abnormality is seen.
`

export default US_CRANIUM;
