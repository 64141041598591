const T_TUBE_CHOLANGIOGRAM_DA = `T-TUBE CHOLANGIOGRAM

Contrast  was injected through the t-tube  and flows freely  through the common  duct into the second part of the duodenum. There is residual  mild dilatation of the  common bile duct.

There is no filling defect  seen in the  common duct to suggest  presence of retain biliary  stone.

IMPRESSION:    Normal examination. No evidence of retained biliary stone detected.


`

export default T_TUBE_CHOLANGIOGRAM_DA;
