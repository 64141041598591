const CECT_NECK = `CECT NECK 
 
Findings:
No enlarged cervical, supraclavicular, axillary or mediastinal lymph nodes seen.
 
The post nasal space, larynx, glottic and infraglottic regions are normal. No focal lesion or collection is seen.

Thyroid, parotid and submandibular glands are normal. No abnormal calcification or focal lesion is seen.

No significant mucosal thickening in the paranasal sinuses.
The right and left mastoid air cells are clear.
 
Visualised right and left lung apices are clear. 
No lung nodules or consolidation are seen.
 
IMPRESSION:   
Normal CT neck.
Please correlate clinically.

`

export default CECT_NECK;
