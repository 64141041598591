const US_THYROID = `ULTRASOUND THYROID
 
Both thyroid lobes are normal in size and vascularity.
No focal solid or cystic lesions are seen.
The isthmus is not thickened.
No enlarged neck nodes seen.
 
IMPRESSION: Normal examination. Please correlate clinically.
`

export default US_THYROID;
