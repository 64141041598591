const CHEST_DA = `CHEST

Heart size normal. Both lung fields appear clear. No hilar abnormality seen.

IMPRESSION:

Normal examination.

`

export default CHEST_DA;
