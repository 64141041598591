const IVU_DA = `IVU

No  urinary tract calculi detected. 

Following contrast injection, there is normal excretion by both kidneys. Right kidney measures 12.9 cm and left kidney 12.8 cm. Both kidneys , pelvicalyceal system, ureters and bladder outline normally.  No urinary obstruction seen.  

IMPRESSION:

Normal examination.

`

export default IVU_DA;
