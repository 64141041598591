const CT_BRAIN_DA = `CT OF THE BRAIN:

0.5 x 64 MDCT of the brain was done without IV contrast.
 
The cerebral hemisphere show normal grey-white matter differentiation.  No  focal cerebral lesion or intracranial mass lesion seen.  The ventricular system and basal cistern appear normal. Brain stem and cerebellum appear normal. 

IMPRESSION:

Normal examination.
`

export default CT_BRAIN_DA;
