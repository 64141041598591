const US_PAEDS_KUB = `PAEDS
ULTRASOUND KUB

Both kidneys are of normal echopattern, size and shape. 
The right and left kidneys measure about 4.7 cm in length. 
No dilatation of the pelvicalyceal system seen.
Normal urinary bladder. No debris seen.
Please correlate clinically.
`

export default US_PAEDS_KUB;
