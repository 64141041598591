const MRI_RIGHT_WRIST_AND_HAND = `MRI RIGHT WRIST AND HAND
 
Sequences:
Axial T1, PDFS
Sagittal T1, PDFS
Coronal T2, PDFS
 
Findings:
There is thickening with fluid surrounding the tendon sheath of middle and ring fingers from metacarpal to proximal interphalangeal joint level. The fluid returns intermediate T1 and high T2 signals. No nodular solid or cystic lesion is seen along the flexor digitorum superficialis and profundus of the middle and ring fingers. No significant tendon sheath thickening of the adjacent index and little fingers seen.
 
Mild thickening of common flexor tendon sheath also seen at the level of wrist joint. No significant tendon sheath fluid collection is seen at this level. No mass lesions seen.
 
The flexor pollicis longus tendon, abductor and adductor pollicis brevis muscles shows normal configuration and signal return.
 
The extensor tendon compartment is normal, no fluid collection within.
 
No bone marrow edema or lesion is seen in the visualised distal radius ulna, carpal, metacarpal and phalanges.
 
The radiocarpal, intercarpal, carpometacarpal and metacarpophalangeal joints appear normal. No prominent joint effusion or erosion is seen.
 
The triangular fibrocartilage complex appears grossly normal. No fluid collection or evidence of injury is seen.
 
IMPRESSION:
Features above are suggestive of tenosynovitis changes involving the flexor tendons of middle and ring fingers of the right hand. Mild inflammatory changes are also seen at the common flexor tendon sheath at wrist joint level. No focal tendon lesion or tear is seen. In the absence of clinical infective markers, repetitive mechanical irritation, autoimmune or atypical infection causes should be considered. Please correlate clinically.
`

export default MRI_RIGHT_WRIST_AND_HAND;
