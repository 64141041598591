const US_DOPPLER = `US  DOPPLER LEFT LOWER LIMB

The left common femoral, superficial femoral, popliteal and distal veins are identified and compressible. Good Doppler flow noted. No thrombus noted.
The  subcutaneous tissue of both calf and thigh region are not thickened or edematous.
No focal collection noted.

IMPRESSION: No deep vein thrombosis seen.

`

export default US_DOPPLER;
