const MRI_RIGHT_SHOULDER_JOINT_II = `MRI RIGHT SHOULDER JOINT
 
Images are partially degraded by metallic artefact from the right clavicle surgical plate.
 
Findings:
 
The humeral head has normal articulation with the normally developed glenoid.
The articular surfaces are smooth and show normal cortical thickness.
No Hill-Sach defect seen. The width of the joint space is normal.
 
The bone marrow displays homogenous, fat-equivalent signal intensity. No focal bone lesion is seen.
 
The glenoid labrum appears intact on all sides.
 
The acromioclavicular joint has normal configuration with no significant hypertrophy. The subacromial fat is intact.
 
The supraspinatus muscle shows normal position and signal characteristics. Its tendon and musculotendinous junction are intact. No evidence of tear is seen.
The rest of rotator cuff muscles show normal configuration, no tear is seen.
 
There is a small rounded hypointensity within the bicipital groove which resembles part of the biceps tendon. Unable to trace the structure superiorly until its expected attachment to the superior lip of the labrum.
 
The deltoid muscle appears grossly normal.
 
IMPRESSION:
1. Suspected tear or partial tear of the long head of biceps tendon.
2. No evidence of rotator cuff or labral tear detected.
Please correlate clinically.
`

export default MRI_RIGHT_SHOULDER_JOINT_II;
