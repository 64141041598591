const MRI_THORACIC_SPINE_DA = `MRI THORACIC SPINE: 

3mm sagittal T1, T2 and 3mm coronal  T2 were acquired through the thoracic spine. 


The thoracic vertebra show normal bony alignment and normal marrow signal intensity. 

The thoracic discs show normal signal intensity. No disc prolapse seen. The spinal canal and lateral neuro foramina appear normal. Spinal cord is normal in calibre and appearance. No paravertebral soft tissue lesion seen. 

IMPRESSION: 

Normal examination. 
`

export default MRI_THORACIC_SPINE_DA;
