const CT_CORONARY_ANGIOGRAM_DA = `CT CORONARY ANGIOGRAM

0.5 mm x 64 MDCT scan of the coronary arteries were done  following intravenous  injection of contrast.

CALCIUM SCORE

The calcium score is 0.

1. RIGHT  CORONARY ARTERY

The right coronary  artery  arises normally from the  anterior aortic sinus. There is no  atheromatous plaque or significant stenosis seen at the orifice, proximal, middle or distal segment.  The RCA supply  the posterior  descending artery.

2. LEFT MAIN CORONARY ARTERY

The left coronary  artery  arises normally from the  posterior aortic sinus. There is no  atheromatous plaque or significant stenosis seen at the orifice or segment of the left  main.

3. LEFT ANTERIOR DESCENDING ARTERY

There is no atheromatous plaque or significant stenosis seen in the proximal, middle or distal segments. The ramus intermedius and diagonal artery outline normally.

4. LEFT  CIRCUMFLEX ARTERY

There is no  atheromatous plaque or significant stenosis  in the proximal and distal segment. Obtuse marginal artery outline normally.

IMPRESSION:

1. Normal examination. No significant disease of the right or left coronary arteries detected.
2. RCA dominant.

`

export default CT_CORONARY_ANGIOGRAM_DA;
