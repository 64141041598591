const BARIUM_SWALLOW_DA = `BARIUM SWALLOW

Barium flows freely down the oesophagus into the stomach. The oesophagus  appear normal. No gastroesophageal  reflux seen during the coarse of the examination. 

The stomach appear normal. Barium flows freely into the duodenum  without gastric outflow obstruction. Duodenum appear normal. No evidence of bowel malrotation seen.

IMPRESSION:

Normal examination.

`

export default BARIUM_SWALLOW_DA;
