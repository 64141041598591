const US_ABDOMEN_AND_PELVIS = `ULTRASOUND ABDOMEN & PELVIS
 
The liver shows fatty changes. The margin is smooth. No focal lesion.
The liver is normal in size and echotexture. The margin is smooth. No focal lesion. 
No other focal liver lesion is seen.
 
A stone and polyp is seen in the gallbladder measures mm.
Previous cholecystectomy. The intrahepatic ducts and common bile duct are not dilated.
The gallbladder is normal. No stone within. The gallbladder wall is not thickened. The intrahepatic ducts and common bile duct are not dilated.
Contracted gallbladder. The intrahepatic ducts and common bile duct are not dilated.
 	
The pancreas and spleen are normal. 
Pancreas is obscured by prominent bowel gas. The spleen is normal.
 
Both kidneys show slightly irregular contour with increased renal parenchymal echogenicity.
Both kidneys are of normal echopattern, size and shape. No stone or hydronephrosis.

Both kidneys are of normal echopattern, size and shape. The right kidney measures cm and the left kidney is about cm in length. The right and left kidneys measure 10.7 cm in length. No stone or hydronephrosis.
The AP diameter of the right and left kidney renal pelvis are 3 - 4 mm (less than 10 mm). 
 
Partially filled urinary bladder.
Normal urinary bladder. No debris or stone within the bladder. 

The prostate gland is not enlarged.
Mildly thickened urinary bladder wall. The prostate gland volume is about 33 mls.
 
Noted previous hysterectomy.
The uterus appears normal, no focal lesion is seen.
The uterus and both ovaries appear normal, no focal lesion is seen.
Uterus is partially obscured, no obvious focal lesion is seen.

Abdominal aorta is normal. No para-aortic lymphadenopathy is seen.
 
No obvious mass lesion or free fluid in the abdomen or pelvis.
 
IMPRESSION:
No abnormality detected.
Normal ultrasound abdomen.
Fatty liver. Liver elastography done.
No gallstone or cholecystitis changes.
Mildly enlarged prostate with chronic bladder outlet obstruction changes.
Please correlate clinically.
`

export default US_ABDOMEN_AND_PELVIS;
