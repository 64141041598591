const MRI_CERVICAL_SPINE = `MRI CERVICAL SPINE
 
Findings:
Straightening of / Loss of normal cervical lordosis.
There is reversed cervical lordosis.
Spondylotic changes seen along the cervical spine.
Normal vertebral body height and marrow signal return.
 
Early desiccated changes at C5/C6 and C6/C7.
 
No significant disc bulge seen along the cervical spine.
 
No canal stenosis or cord compression changes.
No neuroforamina narrowing or impingement of the exiting nerves.
 
No abnormal signal within the spinal cord. No cervical myelopathy changes.
Normal facet joints and ligaments. No ossified posterior longitudinal ligament.
 
IMPRESSION:
1. Multilevel desiccated disc changes.
2. No significant disc bulge, canal stenosis or narrowing of neuroforamina.
Please correlate clinically.
`

export default MRI_CERVICAL_SPINE;
