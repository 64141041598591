const MRI_RIGHT_KNEE_JOINT = `MRI RIGHT KNEE JOINT

Findings:
Small knee joint effusion.

The bones comprising the knee joint show normal configuration and position. No bone bruise is seen.

The articular cartilages are of normal thickness. No obvious tear or degeneration.

The medial and lateral menisci show normal triangular configuration with a homogenous internal structure of low signal intensity. No obvious meniscal tear is seen.

The anterior and posterior cruciate ligaments are normal. No abnormal signal intensity or tear seen.  

Both collateral ligaments are intact and of normal thickness.

Patella and quadriceps tendons are normal.

Irregular chondral thinning seen at the tibial and femoral condyles and patella facets with underlying bone marrow edema and subchondral cyst formation seen. Noted extensive marginal osteophytes formation at the tibiofemoral and patellofemoral compartments.

IMPRESSION:
1. Features above are due to osteoarthritis changes of left knee joint with small joint effusion.
2. Mildly thickened anterior cruciate may be due to sprain. No obvious tears were seen.
3. Complex degenerative tear changes at the medial and lateral menisci.
Please correlate clinically.
`

export default MRI_RIGHT_KNEE_JOINT;
