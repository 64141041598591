const CT_CHEST_ABDOMEN_AND_PELVIS_II_DA = `CT CHEST ABDOMEN AND PELVIS

0.5 x 64 MDCT of the chest , abdomen and pelvis was done following iv , oral and rectal contrast.

Both lung  fields appear clear. No evidence of lung metastases seen. 

No hilar  or mediastinal lymphadenopathy and no  pleural  effusion seen.

The liver and spleen are normal in size and show normal homogenous density and enhancement. No focal intrahepatic mass lesion seen. Gallbladder appear normal. No radio opaque gallstone seen. Bile ducts are not dilated. 

Both kidneys are normal in size and show normal cortical enhancement. No urinary tract calculi or hydronephrosis seen, 

Pancreas appear normal. No pancreatic mass lesion seen. 

Urinary bladder and prostate gland appear normal. Uterus is anteverted and normal in appearance. No intra uterine mass lesion seen. Both ovaries appear normal. 

Bowels are not dilated. No bowel related mass lesion seen. 
No para aortic lymphadenopathy seen 

IMPRESSION:

Normal examination.

`

export default CT_CHEST_ABDOMEN_AND_PELVIS_II_DA;
