const MRI_CERVICAL_SPINE_I_DA = `MRI CERVICAL SPINE

4 mm sagittal T1 and T2 were acquired through the cervical spine. 4 mm axial T2 were acquired the cervical disc spaces.

The cervical vertebra show normal bony alignment and normal marrow signal intensity.

No significant disc herniation seen. The spinal canal and lateral neuro foramina appear normal. Spinal cord is normal in calibre and appearance.

IMPRESSION:

Normal examination.
`

export default MRI_CERVICAL_SPINE_I_DA;
