const MRI_RIGHT_SHOULDER_DA = `MRI RIGHT SHOULDER

3 mm para-coronal T1/T2 and STIR  and 3 mm para-sagittal and axial T2 were acquired through the right shoulder.

The supraspinatus, infraspinatus and subscapularis   muscles and tendon appear intact  showing normal signal intensity. No evidence of rotator cuff tear seen. The acromio-humeral  space appear within normal limit and there is no supraspinatus impingement seen.

The glenoid labrum, glenohumeral and acromio-clavicular joints appear normal.  No marrow abnormality detected.

IMPRESSION: Normal examination.
`

export default MRI_RIGHT_SHOULDER_DA;
