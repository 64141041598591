const MRI_ABDOMEN_DA = `MRI ABDOMEN

6 mm axial T1 and T2 and 6 mm coronal T2 were acquired through the abdomen.

The liver and spleen are normal in size and show normal homogenous signal intensity. No focal intrahepatic lesion seen. Gallbladder appear normal with no filling defect seen to suggest gallstone. Bile ducts are not dilated. 

Pancreas appear normal. No pancreatic mass lesion seen. Pancreatic duct is not dilated. Both kidneys appear normal. No paraaortic lymphadenopathy seen.

No mass or cystic lesion seen in the abdomen.

IMPRESSION:

Normal examination.
`

export default MRI_ABDOMEN_DA;
