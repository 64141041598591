const CT_NECK_DA = `CT NECK

0.5 x 64 MDCT of the neck was done following contrast injection.

The nasopharynx, oropharynx, hypopharynx, larynx and trachea appear normal.

Both parotid, submandibular and thyroid gland are normal in size and appearance. No significant  cervical lymphadenopathy and no soft tissue mass lesion seen in the neck.

IMPRESSION: 

Normal examination.

`

export default CT_NECK_DA;
