const MRI_OF_BRAIN_DA = `MRI  OF BRAIN

5mm axial T1, T2 and FLAIR,  5mm sagittal and coronal T2 were acquired through the brain without IV contrast.  

The cerebral hemisphere show normal grey-white matter signal intensity. No focal cerebral lesion seen.  The ventricular system and basal cistern appear normal. Brainstem and cerebellum appear normal. 

The paranasal sinuses appear clear.

IMPRESSION:

Normal examination.

`

export default MRI_OF_BRAIN_DA;
