const MRI_LUMBAR_SPINE_III_DA = `MRI LUMBAR SPINE
3.5 mm sagittal T1 and T2 were acquired through the lumbar spine. 3.5 mm axial T2 were acquired through the lumbar disc spaces.

The lumbar vertebra show normal bony alignment and normal marrow signal intensity.

The lumbar discs show normal signal intensity. No disc herniation seen. The spinal canal and lateral neuro foramina appear normal. 

MR myelogram show normal dural sac and normal lumbar nerve roots. Facet joints appear normal.

IMPRESSION:

Normal examination. 

`

export default MRI_LUMBAR_SPINE_III_DA;
