const US_KIDNEYS_AND_BLADDER_DA = `ULTRASOUND KIDNEYS AND BLADDER: 

Both kidneys are normal in size, right kidney measures 10.7cm and left kidney 10.6cm and show normal cortical thickness and echogenicity. No evidence of renal calculus or hydronephrosis. Urinary bladder appear normal. 

IMPRESSION:  

Normal examination.

`

export default US_KIDNEYS_AND_BLADDER_DA;
