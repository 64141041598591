const US_ABDOMEN_FEMALE_DA = `ULTRASOUND ABDOMEN (FEMALE)

The liver is not enlarged and has normal homogenous echotexture. No focal intrahepatic lesion seen. Gallbladder  appear normal. No gallstone seen. Bile ducts are  not dilated, CBD measures   mm.

Both kidneys, spleen and pancreas appear normal. 

Urinary bladder appear normal. Uterus is anteverted and normal in size and appearance. No mass lesion seen in the abdomen or pelvis.

IMPRESSION: 

Normal examination.
`

export default US_ABDOMEN_FEMALE_DA;
