const MRI_AND_MRA_OF_BRAIN_DA = `MRI  AND MRA OF BRAIN

5mm axial T1, T2 and FLAIR,  5mm sagittal and coronal T2 were acquired through the brain without IV contrast.  3D MRA of the brain was done.

The cerebral hemisphere show normal grey-white matter signal intensity. No focal cerebral lesion seen.  The ventricular system and basal cistern appear normal. Brainstem and cerebellum appear normal. 

The vertebral, basilar, internal carotid , anterior, middle and posterior cerebral  arteries show normal signal flow and normal in calibre and appearance. No evidence of arterial-stenosis, aneursym or vascular malformation seen. 

The paranasal sinuses appear clear.

IMPRESSION:

Normal examination.
`

export default MRI_AND_MRA_OF_BRAIN_DA;
