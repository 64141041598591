const US_DOPPLER_LEFT_LOWER_LIMB = `ULTRASOUND DOPPLER LEFT LOWER LIMB
 
The common femoral, femoral and popliteal veins are identified and compressible. 
Good Doppler flow noted. No thrombus is seen.
The calf veins are patent and compressible.
 
IMPRESSION:
No evidence of deep vein thrombosis in the left lower limb.
Please correlate clinically.
`

export default US_DOPPLER_LEFT_LOWER_LIMB;
