const MRI_LUMBAR_SPINE_NORMAL = `MRI LUMBAR SPINE (NORMAL)
 
Findings: 
Straightening of / Normal lumbar lordosis, no listhesis. 
Normal vertebral body height, no fracture seen. 
Spondylotic changes are seen along the lumbar spine.

Normal intervertebral discs height and signal characteristics.  
No significant disc bulge, canal stenosis or narrowing of the neuroforamina or lateral recesses. 
No impingement of the traversing or exiting nerves are seen. 
 
Normal conus medullaris and cauda equina. Normal facet joints and ligaments. 

Visualised sacroiliac and hip joints appear normal. 

IMPRESSION: 
Desiccated L5/S1 intervertebral disc. 
No prolapsed intervertebral disc or canal stenosis seen. 
Please correlate clinically.    
`

export default MRI_LUMBAR_SPINE_NORMAL;
