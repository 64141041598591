import React, { useEffect, useState, Suspense } from "react";
import Logo from "../../assets/images/Padimedical.png"; //tukar report template - logo customer
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import dateFormator from "../../utils/dateFormator";
import {
  //tukar template - add template
  CHEST_X_RAY,
  MAMMOGRAM,
  US_ABDOMEN_PELVIS_MALE,
  US_ABDOMEN_PELVIS_FEMALE,
  CERVICAL,
  ABDOMINAL,
  KUB_XRAY,
  SPINE_XRAY,
  KNEE_XRAY,
  CHEST_RADIOGRAPH,
  LIMB_XRAY,
  CT_UROGRAPHY,
  CT_ABDOMEN,
  CTPA_PULMONARY,
  PARANASAL_SINUS,
  PLAIN_CT_BRAIN,
  HRCT_THORAX, 
  CECT_RENAL,
  US_TESTIS_BILATERAL,
  CECT_BRAIN,
  CECT_NECK,
  CECT_NECK_THORAX_ABDOMEN_PELVIS,
  US_DOPPLER,
  MRI_BRAIN_AND_MRA,
  MRI_CERVICAL_SPINE,
  MRI_LUMBAR_SPINE_NORMAL,
  MRI_LUMBAR_SPINE_NORMAL1,
  MRI_LUMBAR_SPINE,
  MRI_RIGHT_KNEE_JOINT,
  MRI_RIGHT_SHOULDER_JOINT_I,
  MRI_RIGHT_SHOULDER_JOINT_II,
  MRI_RIGHT_WRIST_AND_HAND,
  US_ABDOMEN_AND_PELVIS,
  US_LIVER_ELASTOGRAPHY,
  US_PELVIS,
  US_PAEDS_ABDOMEN_AND_PELVIS,
  US_KUB,
  US_PAEDS_KUB,
  US_BOTH_BREASTS,
  US_SCROTUM,
  US_THYROID,
  US_DOPPLER_CAROTID,
  US_CRANIUM,
  US_BOTH_HIPS,
  US_DOPPLER_LEFT_LOWER_LIMB,
  US_LEFT_SHOULDER,
  //-----REPORT DR AZLIN
  AXR_DA,
CXR_DA,
HSG_DA,
IVU_DA,
MCU_DA,
ERCP_DA,
CHEST_DA,
CT_IAM_DA,
CT_NECK_DA,
CT_BRAIN_DA,
CT_CHEST_DA,
LEFT_HAND_DA,
US_BREAST_DA,
US_PELVIS_DA,
US_TESTIS_DA,
CT_URUGRAM_DA,
MRI_ABDOMEN_DA,
BARIUM_ENEMA_DA,
LUMBAR_SPINE_DA,
MRI_OF_BRAIN_DA,
MRI_PERINEUM_DA,
XR_BOTH_KNEE_DA,
MRI_LEFT_KNEE_DA,
XR_RIGHT_KNEE_DA,
BARIUM_SWALLOW_DA,
CERVICAL_SPINE_DA,
CT_COLONOSCOPY_DA,
KNEE_BOTH_XRAY_DA,
MRI_PELVIMETRY_DA,
MRI_RIGHT_KNEE_DA,
MRI_RIGHT_ANKLE_DA,
US_ABDOMEN_FEMALE_DA,
MRI_LUMBAR_SPINE_I_DA,
MRI_RIGHT_SHOULDER_DA,
MRI_THORACIC_SPINE_DA,
MRI_LUMBAR_SPINE_II_DA,
BONE_MINERAL_DENSITY_DA,
CT_PARANASAL_SINUSES_DA,
MRI_AND_MRA_OF_BRAIN_DA,
MRI_CERVICAL_SPINE_I_DA,
MRI_LUMBAR_SPINE_III_DA,
T_TUBE_CHOLANGIOGRAM_DA,
BILATERAL_MAMMOGRAM_I_DA,
CT_ABDOMEN_AND_PELVIS_DA,
CT_CORONARY_ANGIOGRAM_DA,
US_ABDOMEN_AND_PELVIS_DA,
BILATERAL_MAMMOGRAM_II_DA,
US_KIDNEYS_AND_BLADDER_DA,
BILATERAL_MAMMOGRAM_III_DA,
BONE_MINERAL_DENSITY_II_DA,
US_DOPPLER_OF_LEFT_LOWER_LIMB_DA,
CT_CHEST_ABDOMEN_AND_PELVIS_II_DA,



} from "../../report-template/index";
import ActionBoutonView from "../CommonComponents/RessourcesDisplay/ActionButtonView";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import socket from "../../socket/socket";
import "./typing-effect.css";
import Toggle from "react-toggle";
import SweetAlert from "react-bootstrap-sweetalert";
import ReportPoopup from "./ReportPopup/ReportPopup";
const DynamicTable = React.lazy(() => import("./DynamicTable/DynamicTable"));
const getTodayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "/" + mm + "/" + yyyy;
};

const Typing = ({ id }) => {
  const [text, setText] = useState(null);
  let typingTime = new Date();
  useEffect(() => {
    socket.on(id, (payload) => {
      if (payload) {
        setText(
          payload.map((element) => {
            return "  Dr " + element[0].toUpperCase() + element.slice(1);
          })
        );
      } else {
        setText(null);
      }
      typingTime = new Date();
    });
    let interval = setInterval(() => {
      if ((new Date().getTime() - typingTime.getTime()) / 1000 > 1) {
        setText(null);
      }
    }, [1000]);
    return () => {
      clearInterval(interval);
      socket.off(id);
    };
  }, []);
  return (
    <h6
      style={{ textAlign: "right", visibility: text ? "visible" : "hidden" }}
      className="text-primary "
    >
      {text} is typing <span className="dot1">.</span>
      <span className="dot2">.</span>
      <span className="dot3">.</span>
    </h6>
  );
};

const CreateReport = () => {
  const [editing, setEditing] = useState(false);
  const { pid, pname, study_type, study_date, StudyInstanceUID, accessor } =
    useLocation();
  const [isfinalize, setFinalize] = useState(false);
  const [data, setData] = useState({
    patient_name: pname,
    tag: "",
    study_type: study_type,
    study_date: study_date,
    patient_id: null,
    text: null,
    signature: null,
    image: null,
    table: null,
    usg: null,
  });
  const { id } = useParams();
  const roles = useSelector((state) => state.PadiMedical.roles);
  const [can_view, setView] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [tags, setTags] = React.useState([]);
  const [user, setUser] = useState([]);
  const [showtable, setShowTable] = useState(false);
  const [prev_report,setPrevReport]=useState([]);
  const [selected_prev,setSelectedPrev]=useState('');
  const [prevData,setPrevData]=useState(null);
  const history = useHistory();
  let currentClick = null;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector("#main").style.width = "max-content";
    fetchReport();
    fetchPreviousReport();
    fetch("/api/users/radiologist")
      .then((res) => res.json())
      .then((res) => setUser(res))
      .catch((err) => console.log("err"));


    return () => {
      stop_type();
      let element = document.querySelector("#main");
      if (element) element.style.width = "none";
    };
  }, []);

  
  const fetchPreviousReport=()=>{
    fetch(`/api/get-previos-report/${pid}`)
    .then(res=>res.json())
    .then(res=>setPrevReport(res))
    .catch(err=>console.log(err))
  } 

  const handlePrevReport=(e)=>{
    console.log(e.target.value)
    setSelectedPrev(e.target.value)
  }

  const showPopup=()=>{
    if(selected_prev!==''){
      setPrevData(prev_report?.filter(obj=>selected_prev==obj.id)[0])
    }else{
      toast.error('please select previous report to show')
    }
  }
  
  const checkForSignature = () => {
    fetch("/api/users/profile")
      .then((res) => res.json())
      .then((res) => {
        if (!res.signature && roles.signature_compulsory) {
          setTimeout(() => {
            setShowAlert(true);
          }, 2000);
        } else {
          setData((prev) => ({
            ...prev,
            signature: res.signature,
            doctor_description: res.doctor_description,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchReport = () => {
    fetch(`/api/request-report/${id}/is_finalize`)
      .then((res) => res.json())
      .then((res) => setFinalize(res));

    fetch("/api/admin-report", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify({
        studyid: id,
      }),
    })
      .then((res) => {
        if (res.status == 200) return res.json();
        else {
          throw "Bad req";
        }
      })
      .then((res) => {
        setData({
          patient_name: res?.patient_name || pname,
          tag: res?.tag || "",
          study_type: res?.study_type || study_type,
          study_date: res?.study_date || study_date,
          patient_id: res?.patient_id || pid,
          text: res?.text,
          image: res?.image,
          table: res?.table,
          usg_no: res?.usg_no,
          accesor: res?.accesor,
        });
        setTags(res?.doctors == "" ? [] : res?.doctors);
        setEditing(true);
        checkForSignature();
        if (res.study_date) setView(true);
      })
      .catch((err) => {
        setData(prev=>({ ...prev, text: "" }));
        checkForSignature();
      });
  };

  const validate = () => {
    let data1 = [data.patient_name, data.study_type];
    let key = ["patient name", "study type"];
    let error = false;
    data1.map((element, index) => {
      if (element == "" || element == null) {
        error = true;
        toast.error(`${key[index]} field is required`);
      }
    });
    //tukar assign dr- enable compulsory assign dr - disble if compulsuray assign doctor not req --rishabh
    if (tags.toString().replaceAll(" ", "").length === 0) {
      toast.error("Please assign radiologist!");
      error = true;
    }

    if (data.text == "" || data.text == null) {
      toast.error("report is required !!");
      error = true;
    }

    return error;
  };

  const handleChange = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    typing();
  };

  const handleDraft = (table_data) => {
    if (!data.signature && roles.signature_compulsory) {
      setShowAlert(true);
      return;
    }
    if (!data.doctor_description && roles.doctor_description_required) {
      setShowAlert(true);
      return;
    }
    if (!validate()) {
      fetch("/api/create-report-draft", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify({
          patient_name: data.patient_name,
          studyid: id,
          text: data?.text || "",
          ...data,
          patient_id: data.patient_id || pid,
          doctors: tags,
          StudyInstanceUID: StudyInstanceUID,
          accesor: accessor,
          table: table_data,
        }),
      }).then((res) => {
        if (res.status == 201) {
          fetchReport();
          toast.success("draft report saved !!");
          setView(true);
        } else {
          toast.error("something went wrong !!");
        }
      });
    }
  };

  const handleFinalize = (table_data) => {
    if (!data.signature && roles.signature_compulsory) {
      setShowAlert(true);
      return;
    }
    if (!data.doctor_description && roles.doctor_description_required) {
      setShowAlert(true);
      return;
    }
    let temp = data.text;
    if (data.doctor_description && temp) {
      temp = temp.split("Reported by:")[0];
      temp = temp + "Reported by:" + "\n" + data.doctor_description;
    }

    if (!validate()) {
      setFinalize(true);
      fetch("/api/create-report-final", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify({
          ...data,
          patient_name: data.patient_name,
          studyid: id,
          text: temp || "",
          tag: "",
          created_by: roles?.firstname || roles.username,
          patient_id: data.patient_id || pid,
          practicing_no: roles?.practicing_no,
          doctors: tags || [],
          StudyInstanceUID: StudyInstanceUID,
          accesor: accessor || data.accesor,
          table: table_data,
        }),
      }).then((res) => {
        if (res.status == 201) {
          setTimeout(() => {
            fetchReport();
          }, [2000]);
          toast.success("report saved !!");
          setView(true);
        } else {
          toast.error("something went wrong !!");
        }
      });
    }
  };

  const Addendum = (table_data) => {
    if (!data.signature && roles.signature_compulsory) {
      setShowAlert(true);
      return;
    }
    if (!data.doctor_description && roles.doctor_description_required) {
      setShowAlert(true);
      return;
    }
    console.log("addend ",data.text)
    let temp = data.text;
    if (data.doctor_description && temp) {
      temp = temp.split("Reported by:")[0];
      temp = temp + "Reported by:" + "\n" + data.doctor_description;
    }

    console.log({temp})

    fetch("/api/create-report-final", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify({
        ...data,
        patient_name: data.patient_name,
        studyid: id,
        text: temp || "",
        addendumby: roles?.firstname || roles.username,
        addendum_at: getTodayDate(),
        patient_id: data.patient_id || pid,
        practicing_no: roles?.practicing_no,
        doctors: tags || [],
        StudyInstanceUID: StudyInstanceUID,
        accesor: accessor,
        table: table_data,
      }),
    }).then((res) => {
      if (res.status == 201) {
        fetchReport();
        toast.success("report saved !!");
        setView(true);
      } else {
        toast.error("something went wrong !!");
      }
    });
  };

  async function encodeImageFileAsURL(element) {
    var file = element.target.files[0];
    // console.log(data)
    var reader = new FileReader();

    reader.onloadend = function () {
      setData(prev=>({ ...prev, signature: reader.result }));
    };
    reader.readAsDataURL(file);
  }

  async function encodePDFImageFileAsURL(element) {
    var file = element.target.files[0];
    // console.log(data)
    var reader = new FileReader();

    reader.onloadend = function () {
      setData(prev=>({ ...prev, image: reader.result }));
    };
    reader.readAsDataURL(file);
  }

  const deleteReport = () => {
    fetch("/api/admin-report", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      method: "DELETE",
      body: JSON.stringify({
        studyid: id,
      }),
    }).then(() => {
      setData({
        patient_name: pname,
        tag: "",
        study_type: study_type,
        study_date: study_date,
        patient_id: null,
        text: null,
        signature: null,
        image: null,
      });
      fetchReport();
      setView(false);
      toast.success("report deleted !!");
    });
  };

  const setTemplate = (e) => {
    // eslint-disable-next-line default-case
    switch (e.target.value) {
      case "CHEST_X_RAY": {
        let temp = CHEST_X_RAY;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MAMMOGRAM": {
        let temp = MAMMOGRAM;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_ABDOMEN_PELVIS_MALE": {
        let temp = US_ABDOMEN_PELVIS_MALE;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_ABDOMEN_PELVIS_FEMALE": {
        let temp = US_ABDOMEN_PELVIS_FEMALE;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CERVICAL": {
        let temp = CERVICAL;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }
      case "ABDOMINAL": {
        let temp = ABDOMINAL;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "KUB_XRAY": {
        let temp = KUB_XRAY;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "SPINE_XRAY": {
        let temp = SPINE_XRAY;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "KNEE_XRAY": {
        let temp = KNEE_XRAY;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CHEST_RADIOGRAPH": {
        let temp = CHEST_RADIOGRAPH;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "LIMB_XRAY": {
        let temp = LIMB_XRAY;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_UROGRAPHY": {
        let temp = CT_UROGRAPHY;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }
      case "CT_ABDOMEN": {
        let temp = CT_ABDOMEN;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CTPA_PULMONARY": {
        let temp = CTPA_PULMONARY;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "PARANASAL_SINUS": {
        let temp = PARANASAL_SINUS;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "PLAIN_CT_BRAIN": {
        let temp = PLAIN_CT_BRAIN;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "HRCT_THORAX": {
        let temp = HRCT_THORAX;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CECT_RENAL": {
        let temp = CECT_RENAL;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_TESTIS_BILATERAL": {
        let temp = US_TESTIS_BILATERAL;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CECT_BRAIN": {
        let temp = CECT_BRAIN;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CECT_NECK": {
        let temp = CECT_NECK;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CECT_NECK_THORAX_ABDOMEN_PELVIS": {
        let temp = CECT_NECK_THORAX_ABDOMEN_PELVIS;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_DOPPLER": {
        let temp = US_DOPPLER;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_BRAIN_AND_MRA": {
        let temp = MRI_BRAIN_AND_MRA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_CERVICAL_SPINE": {
        let temp = MRI_CERVICAL_SPINE;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_LUMBAR_SPINE_NORMAL": {
        let temp = MRI_LUMBAR_SPINE_NORMAL;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_LUMBAR_SPINE_NORMAL1": {
        let temp = MRI_LUMBAR_SPINE_NORMAL1;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_LUMBAR_SPINE": {
        let temp = MRI_LUMBAR_SPINE;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_RIGHT_KNEE_JOINT": {
        let temp = MRI_RIGHT_KNEE_JOINT;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_RIGHT_SHOULDER_JOINT_I": {
        let temp = MRI_RIGHT_SHOULDER_JOINT_I;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_RIGHT_SHOULDER_JOINT_II": {
        let temp = MRI_RIGHT_SHOULDER_JOINT_II;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_RIGHT_WRIST_AND_HAND": {
        let temp = MRI_RIGHT_WRIST_AND_HAND;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_ABDOMEN_AND_PELVIS": {
        let temp = US_ABDOMEN_AND_PELVIS;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_LIVER_ELASTOGRAPHY": {
        let temp = US_LIVER_ELASTOGRAPHY;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_PELVIS": {
        let temp = US_PELVIS;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_PAEDS_ABDOMEN_AND_PELVIS": {
        let temp = US_PAEDS_ABDOMEN_AND_PELVIS;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_KUB": {
        let temp = US_KUB;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_PAEDS_KUB": {
        let temp = US_PAEDS_KUB;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_BOTH_BREASTS": {
        let temp = US_BOTH_BREASTS;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_SCROTUM": {
        let temp = US_SCROTUM;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_THYROID": {
        let temp = US_THYROID;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_DOPPLER_CAROTID": {
        let temp = US_DOPPLER_CAROTID;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_CRANIUM": {
        let temp = US_CRANIUM;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_BOTH_HIPS": {
        let temp = US_BOTH_HIPS;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_DOPPLER_LEFT_LOWER_LIMB": {
        let temp = US_DOPPLER_LEFT_LOWER_LIMB;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_LEFT_SHOULDER": {
        let temp = US_LEFT_SHOULDER;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      //DR AZLIN REPORT

      case "AXR_DA": {
        let temp = AXR_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CXR_DA": {
        let temp = CXR_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "HSG_DA": {
        let temp = HSG_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "IVU_DA": {
        let temp = IVU_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MCU_DA": {
        let temp = MCU_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "ERCP_DA": {
        let temp = ERCP_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CHEST_DA": {
        let temp = CHEST_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_IAM_DA": {
        let temp = CT_IAM_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_NECK_DA": {
        let temp = CT_NECK_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_BRAIN_DA": {
        let temp = CT_BRAIN_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_CHEST_DA": {
        let temp = CT_CHEST_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "LEFT_HAND_DA": {
        let temp = LEFT_HAND_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_BREAST_DA": {
        let temp = US_BREAST_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_PELVIS_DA": {
        let temp = US_PELVIS_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_TESTIS_DA": {
        let temp = US_TESTIS_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_URUGRAM_DA": {
        let temp = CT_URUGRAM_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_ABDOMEN_DA": {
        let temp = MRI_ABDOMEN_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "BARIUM_ENEMA_DA": {
        let temp = BARIUM_ENEMA_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "LUMBAR_SPINE_DA": {
        let temp = LUMBAR_SPINE_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_OF_BRAIN_DA": {
        let temp = MRI_OF_BRAIN_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_PERINEUM_DA": {
        let temp = MRI_PERINEUM_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "XR_BOTH_KNEE_DA": {
        let temp = XR_BOTH_KNEE_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_LEFT_KNEE_DA": {
        let temp = MRI_LEFT_KNEE_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "XR_RIGHT_KNEE_DA": {
        let temp = XR_RIGHT_KNEE_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "BARIUM_SWALLOW_DA": {
        let temp = BARIUM_SWALLOW_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CERVICAL_SPINE_DA": {
        let temp = CERVICAL_SPINE_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_COLONOSCOPY_DA": {
        let temp = CT_COLONOSCOPY_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "KNEE_BOTH_XRAY_DA": {
        let temp = KNEE_BOTH_XRAY_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_PELVIMETRY_DA": {
        let temp = MRI_PELVIMETRY_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_RIGHT_KNEE_DA": {
        let temp = MRI_RIGHT_KNEE_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_RIGHT_ANKLE_DA": {
        let temp = MRI_RIGHT_ANKLE_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_ABDOMEN_FEMALE_DA": {
        let temp = US_ABDOMEN_FEMALE_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_LUMBAR_SPINE_I_DA": {
        let temp = MRI_LUMBAR_SPINE_I_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_RIGHT_SHOULDER_DA": {
        let temp = MRI_RIGHT_SHOULDER_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_THORACIC_SPINE_DA": {
        let temp = MRI_THORACIC_SPINE_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_LUMBAR_SPINE_II_DA": {
        let temp = MRI_LUMBAR_SPINE_II_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "BONE_MINERAL_DENSITY_DA": {
        let temp = BONE_MINERAL_DENSITY_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_PARANASAL_SINUSES_DA": {
        let temp = CT_PARANASAL_SINUSES_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_AND_MRA_OF_BRAIN_DA": {
        let temp = MRI_AND_MRA_OF_BRAIN_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_CERVICAL_SPINE_I_DA": {
        let temp = MRI_CERVICAL_SPINE_I_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "MRI_LUMBAR_SPINE_III_DA": {
        let temp = MRI_LUMBAR_SPINE_III_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "T_TUBE_CHOLANGIOGRAM_DA": {
        let temp = T_TUBE_CHOLANGIOGRAM_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "BILATERAL_MAMMOGRAM_II_DA": {
        let temp = BILATERAL_MAMMOGRAM_II_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_ABDOMEN_AND_PELVIS_DA": {
        let temp = CT_ABDOMEN_AND_PELVIS_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_CORONARY_ANGIOGRAM_DA": {
        let temp = CT_CORONARY_ANGIOGRAM_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_ABDOMEN_AND_PELVIS_DA": {
        let temp = US_ABDOMEN_AND_PELVIS_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "BILATERAL_MAMMOGRAM_II_DA": {
        let temp = BILATERAL_MAMMOGRAM_II_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_KIDNEYS_AND_BLADDER_DA": {
        let temp = US_KIDNEYS_AND_BLADDER_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "BILATERAL_MAMMOGRAM_III_DA": {
        let temp = BILATERAL_MAMMOGRAM_III_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "BONE_MINERAL_DENSITY_II_DA": {
        let temp = BONE_MINERAL_DENSITY_II_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "US_DOPPLER_OF_LEFT_LOWER_LIMB_DA": {
        let temp = US_DOPPLER_OF_LEFT_LOWER_LIMB_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

      case "CT_CHEST_ABDOMEN_AND_PELVIS_II_DA": {
        let temp = CT_CHEST_ABDOMEN_AND_PELVIS_II_DA;
        if (data.doctor_description) {
          temp = temp.split("Reported by:")[0];
          temp = temp + "Reported by:" + "\n" + data.doctor_description;
        }
        setData({ ...data, text: temp });
        break;
      }

    }
  };

  const typing = () => {
    socket.emit("typing", { study_id: id });
  };

  const stop_type = () => {
    socket.emit("stop-typing", { study_id: id });
  };

  console.log('text=>',data.text)

  const getData = (data) => {
    switch (currentClick) {
      case "Draft":
        handleDraft(JSON.stringify(data));
        break;
      case "Finalize":
        handleFinalize(JSON.stringify(data));
        break;
      case "Addendum":
        Addendum(JSON.stringify(data));
        break;
      default:
        return;
    }
  };

  const Click = (e, type) => {
    e.preventDefault();
    switch (type) {
      case "Draft":
        currentClick = "Draft";
        break;
      case "Finalize":
        currentClick = "Finalize";
        break;
      case "Addendum":
        currentClick = "Addendum";
        break;
      default:
        return;
    }
    let btn = document.querySelectorAll(".dynamic-table-wrapper div button");
    if (btn && btn[2]) {
      btn = btn[2];
      btn.click();
    } else {
      switch (type) {
        case "Draft":
          handleDraft(data.table);
          break;
        case "Finalize":
          handleFinalize(data.table);
          break;
        case "Addendum":
          Addendum(data.table);
          break;
        default:
          return;
      }
    }
    //  console.log('btn=>', btn)
    return;
  };

  const gotoProfile = () => {
    history.push("/dashboard");
  };
  return (
    <>
      <SweetAlert
        show={showAlert}
        warning
        showCancel
        confirmBtnText="Let's do it !"
        confirmBtnBsStyle="sucess"
        title="It seems you didn't updated signature , doctor-description on your profile !!, To Finalize Report we must need your signature , doctor-description"
        onConfirm={gotoProfile}
        onCancel={() => {
          setShowAlert((prev) => !prev);
        }}
        focusConfirmBtn
      ></SweetAlert>
      <ReportPoopup data={prevData} setPrevData={setPrevData} display={prevData?'block':'none'}/>
      <div className="mb-5 create-report">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img height={130} src={Logo} />
        </div>
        <form
          style={{ maxWidth: 1400, marginTop: 10, minWidth: 800 }} //tukar report template - change logo size
          className="container"
        >
          <div className="row d-flex">
            <div className="col-6">
              <label for="pname" class="form-label">
                Patient's Name
              </label>
              <input
                required
                value={data.patient_name}
                name="patient_name"
                onChange={handleChange}
                id="pname"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label for="pid" class="form-label">
                Patient's ID
              </label>
              <input
                value={data.patient_id || pid}
                readOnly
                id="pid"
                className="form-control"
              />
            </div>
          </div>
          <br />
          <div className="row d-flex">
            <div className="col-6">
              <label for="stype" class="form-label">
                Study Type
              </label>
              <input
                id="stype"
                name="study_type"
                value={data.study_type}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>

            <div className="col-6">
              <label for="sdata" class="form-label">
                Study Date
              </label>
              <input
                id="sdata"
                name="study_date"
                value={data.study_date || study_date}
                className="form-control"
                readOnly
              />
            </div>
          </div>

          <div className="row d-flex">
            <div className="col-6">
              <label for="stype" class="form-label">
                Upload Radiologist Signature{" "}
                {/*tukar nama - Upload to Upload Radiologist Signature */}
              </label>
              <input
                id="stype"
                type="file"
                name="study_type"
                onChange={encodeImageFileAsURL}
                className="form-control"
              />
            </div>

            <div className="col-6">
              <label for="stype" class="form-label">
                Select Template
              </label>
              <input
                list="templates"
                onChange={setTemplate}
                class="form-control col-sm-6 custom-select custom-select-sm"
                name="template"
                id="template"
              />
              {/* tukar template - add template */}
              <datalist id="templates">
                <option value={"CHEST_X_RAY"}>CHEST X-RAY NORMAL</option>
                <option value={"MAMMOGRAM"}>MAMMOGRAM</option>
                <option value={"US_ABDOMEN_PELVIS_MALE"}>US ABDOMEN PELVIS MALE</option>
                <option value={"US_ABDOMEN_PELVIS_FEMALE"}>US ABDOMEN PELVIS FEMALE</option>
                <option value={"CERVICAL"}>SPINE XRAT – SPONDYLOSIS</option>
                <option value={"ABDOMINAL"}>ABDOMINAL X-RAY NORMAL</option>
                <option value={"KUB_XRAY"}>KUB X-RAY NORMAL</option>
                <option value={"SPINE_XRAY"}>SPINE X-RAY NORMAL</option>
                <option value={"KNEE_XRAY"}>KNEE X-RAY - OSTEOARTHRITIS</option>
                <option value={"CHEST_RADIOGRAPH"}>CHEST X-RAY PEADS INFECTION</option>
                <option value={"LIMB_XRAY"}>LIMB X-RAY NORMAL</option>
                <option value={"CT_UROGRAPHY"}>CT UROGRAPHY - MALE (NORMAL)</option>
                <option value={"CT_ABDOMEN"}>CT ABDOMEN – MALE (NORMAL)</option>
                <option value={"CTPA_PULMONARY"}>CTPA – PULMONARY EMBOLISM</option>
                <option value={"PARANASAL_SINUS"}>CT PARANASAL SINUS</option>
                <option value={"CECT_BRAIN"}>PLAIN CT BRAIN</option>
                <option value={"HRCT_THORAX"}>HRCT THORAX</option>
                <option value={"CECT_RENAL"}>CECT RENAL</option>
                <option value={"US_TESTIS_BILATERAL"}>US TESTIS BILATERAL</option>
                <option value={"CECT_NECK"}>CECT NECK</option>
                <option value={"CECT_NECK_THORAX_ABDOMEN_PELVIS"}>CECT NECK, THORAX, ABDOMEN AND PELVIS</option>
                <option value={"US_DOPPLER"}>US DOPPLER</option>
                <option value={"MRI_BRAIN_AND_MRA"}>MRI BRAIN AND MRA - DR FAIZAL</option>
                <option value={"MRI_CERVICAL_SPINE"}>MRI CERVICAL SPINE - DR FAIZAL</option>
                <option value={"MRI_LUMBAR_SPINE_NORMAL"}>MRI LUMBAR SPINE (NORMAL) - DR FAIZAL</option>
                <option value={"MRI_LUMBAR_SPINE"}>MRI LUMBAR SPINE (NORMAL) - DR AMRAN</option>
                <option value={"MRI_LUMBAR_SPINE"}>MRI LUMBAR SPINE - DR FAIZAL</option>
                <option value={"MRI_RIGHT_KNEE_JOINT"}>MRI RIGHT KNEE JOINT - DR FAIZAL</option>
                <option value={"MRI_RIGHT_SHOULDER_JOINT_I"}>MRI RIGHT SHOULDER JOINT I - DR FAIZAL</option>
                <option value={"MRI_RIGHT_SHOULDER_JOINT_II"}>MRI RIGHT SHOULDER JOINT II - DR FAIZAL</option>
                <option value={"MRI_RIGHT_WRIST_AND_HAND"}>MRI RIGHT WRIST AND HAND - DR FAIZAL</option>
                <option value={"US_ABDOMEN_AND_PELVIS"}>ULTRASOUND ABDOMEN AND PELVIS - DR FAIZAL</option>
                <option value={"US_LIVER_ELASTOGRAPHY"}>ULTRASOUND LIVER ELASTOGRAPHY - DR FAIZAL</option>
                <option value={"US_PELVIS"}>ULTRASOUND PELVIS - DR FAIZAL</option>
                <option value={"US_PAEDS_ABDOMEN_AND_PELVIS"}>(PAEDS) ULTRASOUND ABDOMEN AND PELVIS</option>
                <option value={"US_KUB"}>ULTRASOUND KUB</option>
                <option value={"US_PAEDS_KUB"}>(PAEDS) ULTRASOUND KUB - DR FAIZAL</option>
                <option value={"US_BOTH_BREASTS"}>ULTRASOUND BOTH BREASTS - DR FAIZAL</option>
                <option value={"US_SCROTUM"}>ULTRASOUND SCROTUM - DR FAIZAL</option>
                <option value={"US_THYROID"}>ULTRASOUND THYROID - DR FAIZAL</option>
                <option value={"US_DOPPLER_CAROTID"}>ULTRASOUND DOPPLER CAROTID - DR FAIZAL</option>
                <option value={"US_CRANIUM"}>ULTRASOUND CRANIUM - DR FAIZAL</option>
                <option value={"US_BOTH_HIPS"}>ULTRASOUND BOTH HIPS - DR FAIZAL</option>
                <option value={"US_DOPPLER_LEFT_LOWER_LIMB"}>ULTRASOUND DOPPLER LEFT LOWER LIMB - DR FAIZAL</option>
                <option value={"US_LEFT_SHOULDER"}>ULTRASOUND LEFT SHOULDER - DR FAIZAL</option>
                {/* DR AZLIN TEMPLATE */}
                <option value={"AXR_DA"}>AXR - DR AZLIN</option>
                <option value={"CXR_DA"}>CXR - DR AZLIN</option>
                <option value={"HSG_DA"}>HSG - DR AZLIN</option>
                <option value={"IVU_DA"}>IVU - DR AZLIN</option>
                <option value={"MCU_DA"}>MCU - DR AZLIN</option>
                <option value={"ERCP_DA"}>ERCP - DR AZLIN</option>
                <option value={"LUMBAR_SPINE_DA"}>LUMBAR SPINE - DR AZLIN</option>
                <option value={"CERVICAL_SPINE_DA"}>CERVICAL SPINE - DR AZLIN</option>
                <option value={"KNEE_BOTH_XRAY_DA"}>ULTRASOUND LEFT SHOULDER - DR AZLIN</option>
                <option value={"T_TUBE_CHOLANGIOGRAM_DA"}>KNEE BOTH XRAY - DR AZLIN</option>
                <option value={"LEFT_HAND_DA"}>LEFT HAND - DR AZLIN</option>
                <option value={"CHEST_DA"}>CHEST - DR AZLIN</option>
                <option value={"CT_IAM_DA"}>CT IAM - DR AZLIN</option>
                <option value={"CT_NECK_DA"}>CT NECK - DR AZLIN</option>
                <option value={"CT_BRAIN_DA"}>CT BRAIN - DR AZLIN</option>
                <option value={"CT_CHEST_DA"}>CT CHEST - DR AZLIN</option>
                <option value={"CT_URUGRAM_DA"}>CT URUGRAM - DR AZLIN</option>
                <option value={"CT_COLONOSCOPY_DA"}>CT COLONOSCOPY - DR AZLIN</option>
                <option value={"CT_PARANASAL_SINUSES_DA"}>CT PARANASAL SINUSES - DR AZLIN</option>
                <option value={"CT_ABDOMEN_AND_PELVIS_DA"}>CT ABDOMEN AND PELVIS I - DR AZLIN</option>
                <option value={"CT_CHEST_ABDOMEN_AND_PELVIS_II_DA"}>CT ABDOMEN AND PELVIS II - DR AZLIN</option>
                <option value={"CT_CORONARY_ANGIOGRAM_DA"}>CT CORONARY ANGIOGRAM - DR AZLIN</option>
                <option value={"US_BREAST_DA"}>US BREAST - DR AZLIN</option>
                <option value={"US_PELVIS_DA"}>US PELVIS - DR AZLIN</option>
                <option value={"US_TESTIS_DA"}>US TESTIS - DR AZLIN</option>
                <option value={"US_ABDOMEN_FEMALE_DA"}>US ABDOMEN FEMALE - DR AZLIN</option>
                <option value={"US_ABDOMEN_AND_PELVIS_DA"}>US ABDOMEN AND PELVIS - DR AZLIN</option>
                <option value={"US_KIDNEYS_AND_BLADDER_DA"}>US KIDNEYS AND BLADDER - DR AZLIN</option>
                <option value={"US_DOPPLER_OF_LEFT_LOWER_LIMB_DA"}>US DOPPLER OF LEFT LOWER LIMB - DR AZLIN</option>
                <option value={"XR_BOTH_KNEE_DA"}>XR BOTH KNEE - DR AZLIN</option>
                <option value={"XR_RIGHT_KNEE_DA"}>XR RIGHT KNEE - DR AZLIN</option>
                <option value={"MRI_ABDOMEN_DA"}>MRI ABDOMEN - DR AZLIN</option>
                <option value={"MRI_OF_BRAIN_DA"}>MRI OF BRAIN - DR AZLIN</option>
                <option value={"MRI_PERINEUM_DA"}>MRI PERINEUM - DR AZLIN</option>
                <option value={"MRI_LEFT_KNEE_DA"}>MRI LEFT KNEE - DR AZLIN</option>
                <option value={"MRI_PELVIMETRY_DA"}>MRI PELVIMETRY - DR AZLIN</option>
                <option value={"MRI_RIGHT_KNEE_DA"}>MRI RIGHT KNEE - DR AZLIN</option>
                <option value={"MRI_RIGHT_ANKLE_DA"}>MRI RIGHT ANKLE - DR AZLIN</option>
                <option value={"MRI_LUMBAR_SPINE_I_DA"}>MRI LUMBAR SPINE I - DR AZLIN</option>
                <option value={"MRI_LUMBAR_SPINE_II_DA"}>MRI LUMBAR SPINE II - DR AZLIN</option>
                <option value={"MRI_LUMBAR_SPINE_III_DA"}>MRI LUMBAR SPINE III - DR AZLIN</option>
                <option value={"MRI_RIGHT_SHOULDER_DA"}>MRI RIGHT SHOULDER - DR AZLIN</option>
                <option value={"MRI_THORACIC_SPINE_DA"}>MRI THORACIC SPINE - DR AZLIN</option>
                <option value={"MRI_AND_MRA_OF_BRAIN_DA"}>MRI AND MRA OF BRAIN - DR AZLIN</option>
                <option value={"MRI_CERVICAL_SPINE_I_DA"}>MRI CERVICAL SPINE - DR AZLIN</option>
                <option value={"BILATERAL_MAMMOGRAM_I_DA"}>BILATERAL MAMMOGRAM I - DR AZLIN</option>
                <option value={"BILATERAL_MAMMOGRAM_II_DA"}>BILATERAL MAMMOGRAM II - DR AZLIN</option>
                <option value={"BILATERAL_MAMMOGRAM_III_DA"}>BILATERAL MAMMOGRAM III - DR AZLIN</option>
                <option value={"BONE_MINERAL_DENSITY_DA"}>BONE MINERAL DENSITY I - DR AZLIN</option>
                <option value={"BONE_MINERAL_DENSITY_II_DA"}>BONE MINERAL DENSITY II - DR AZLIN</option>
                <option value={"BARIUM_ENEMA_DA"}>BARIUM ENEMA - DR AZLIN</option>
                <option value={"BARIUM_SWALLOW_DA"}>BARIUM SWALLOW - DR AZLIN</option>
                
              </datalist>
            </div>
          </div>
          <div className="row d-flex">
            {roles?.report_with_pdf ? (
              <div className="col-6">
                <label for="report_image" class="form-label">
                  Upload Report Image
                </label>
                <input
                  id="report_image"
                  type="file"
                  name="report_image"
                  onChange={encodePDFImageFileAsURL}
                  className="form-control"
                />
              </div>
            ) : null}

            <div
              style={{
                alignItems: "flex-end",
                display: "flex",
                justifyContent: "left",
              }}
              className="col-6"
            >
              <ActionBoutonView
                //tukar link - osimis viewer
                StudyInstanceUID={StudyInstanceUID}
                wsi_link={
                  "https://kpjsibusvr.padimedical.com/wsi/app/index.html?series=" +
                  id  //For rishab to adds on - add SeriesOrthancID
                }
                osimis_link={
                  "https://kpjsibusvr.padimedical.com/osimis-viewer/app/index.html?study=" +
                  id
                }
                OhifLink={"/viewer-ohif/viewer/" + StudyInstanceUID}
                radiant={
                  "radiant://?n=pstv&v=0020000D&v=%22" + StudyInstanceUID
                }
                osirix={
                  "osirix://?methodName=downloadURL&URL=https://kpjsibusvr.padimedical.com/studies/" +
                  id +
                  "/archive"
                }
                downloadzip={
                  "https://kpjsibusvr.padimedical.com/studies/" + id + "/archive"
                }
              />
            </div>
          </div>
          <br />

          <div className="row d-flex">
            {roles.can_assign_doctors ? (
              <div className="col-6">
                <label for="stype" class="form-label">
                  Assign Radiologist
                </label>
                <Typeahead
                  multiple
                  onChange={(selected) => {
                    setTags(selected);
                  }}
                  options={user.map(
                    (element) =>
                      `Dr. ${element.firstname}  ${element.lastname} (${element.username})`
                  )}
                  selected={tags}
                  id="doctors"
                />
              </div>
            ) : null}

            {roles.usg_no && (
              <div className="col-6">
                <label for="stype" class="form-label">
                  USG NO
                </label>
                <input
                  class="form-control col-sm-6 "
                  value={data.usg_no}
                  name="usg_no"
                  onChange={handleChange}
                />
              </div>
            )}
          </div>

          <div className="row d-flex">
          <div className="col-6">
              <label for="stype" class="form-label">
                Select Previous Report
              </label>
              <div className="d-flex">
              <select onChange={handlePrevReport} className="form-control">
                <option hidden>select previous report</option>
                {prev_report?.map((obj)=><option value={obj.id}>{obj.study_type} -  {dateFormator(obj.study_date)}</option>)}
              </select>
              <button onClick={showPopup} type="button" className="btn mr-5 otjs-button-blue text-light">show</button>
              </div>
            </div>
          </div>
          <br />
          <br />
          {roles.can_add_table && (
            <>
              <div className="d-flex justify-content-start align-items-center ">
                <span className="h5">Show Table</span>
                <Toggle
                  className="ms-2"
                  checked={showtable}
                  onChange={(value) => setShowTable(!showtable)}
                />
              </div>
              {
                <div style={{ display: `${showtable ? "block" : "none"}` }}>
                  <Suspense fallback={null}>
                    <DynamicTable data={data.table} getData={getData} />
                  </Suspense>
                </div>
              }
            </>
          )}

          <div className="row d-flex">
            <div className="col-12">
              <Typing id={id} />
              <textarea
                className="form-control"
                cols="12"
                rows="20"
                name="text"
                value={data?.text}
                onChange={handleChange}
                onBlur={stop_type}
              />
            </div>
          </div>
          <br />
          <div className="d-flex">
            {editing ? (
              <Button
                disabled={!roles.edit_patient_report || isfinalize}
                type="submit"
                onClick={(e) => Click(e, "Draft")}
                className="btn m-1 "
              >
                Save
              </Button>
            ) : (
              <Button
                disabled={!roles.create_patient_report || isfinalize}
                type="submit"
                onClick={(e) => Click(e, "Draft")}
                className="btn m-1 "
              >
                Save
              </Button>
            )}

            <Link
              to={{
                pathname: `/report/view/${id}`,
                study_date: study_date,
                StudyInstanceUID,
              }}
            >
              <Button disabled={!isfinalize} className="btn m-1">
                View
              </Button>
            </Link>

            <Link
              to={{
                pathname: `/report/view/${id}`,
                study_date: study_date,
                StudyInstanceUID,
                preview: true,
              }}
            >
              <Button className="btn m-1">Preview</Button>
            </Link>

            <div className="ms-auto">
              {roles.delete_report ? (
                <Button onClick={deleteReport} className="btn m-1 btn-danger">
                  Delete
                </Button>
              ) : null}

              {roles?.addendun ? (
                <Button
                  disabled={!isfinalize}
                  onClick={(e) => Click(e, "Addendum")}
                  className="btn m-1 "
                >
                  Addendum
                </Button>
              ) : null}

              {editing ? (
                <Button
                  disabled={
                    !(roles.edit_patient_report && roles.can_finalize_report) ||
                    isfinalize
                  }
                  onClick={(e) => Click(e, "Finalize")}
                  className="btn m-1"
                >
                  Finalize
                </Button>
              ) : (
                <Button
                  disabled={
                    !(
                      roles.create_patient_report && roles.can_finalize_report
                    ) || isfinalize
                  }
                  onClick={(e) => Click(e, "Finalize")}
                  className="btn m-1"
                >
                  Finalize
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateReport;
