const CT_CHEST_DA = `CT CHEST

0.5 x 64 MDCT of the chest was done through the chest following contrast injection.

Both lung  fields appear clear. No evidence of lung metastases seen. 

No hilar  or mediastinal lymphadenopathy and no  pleural  effusion seen.

IMPRESSION:

Normal examination.

`

export default CT_CHEST_DA;
