const MRI_RIGHT_SHOULDER_JOINT_I = `MRI RIGHT SHOULDER JOINT
 
Findings:
The humeral head has normal articulation with the normally developed glenoid.
The articular surfaces are smooth and show normal cortical thickness.
No focal cortical defect is seen. The joint space is normal.
 
The bone marrow displays homogenous, fat-equivalent signal intensity.
No focal bone lesion is seen.
 
The glenoid labrum appear intact on all sides.
 
The acromioclavicular joint has normal configuration with no hypertrophy.
The subacromial fat is intact.
 
The supraspinatus muscle shows normal position and signal characteristics. Its tendon and musculotendinous junction are intact. No evidence of tear is seen.
The rest of rotator cuff muscles show normal configuration, no tear is seen.
 
The bicep tendon is intact and occupies a normal position in the bicipital groove and shows normal attachment at the superior labrum.

The deltoid muscle appears normal.
 
IMPRESSION:
 
Normal MRI Shoulder Joint
`

export default MRI_RIGHT_SHOULDER_JOINT_I;
