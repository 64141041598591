const MRI_RIGHT_ANKLE_DA = `MRI RIGHT ANKLE 

Anterior talo fibula ligament (ATFL), anterior tibio fibula ligament, posterior talo fibula ligament, medial deltoid ligament and tendo Achilles appear intact showing normal signal intensity. 

There is no bony contusion seen. Ankle and subtalar joints appear normal. There is no periarticular soft tissue oedema seen. 

IMPRESSION: 

No significant abnormality detected. 
`

export default MRI_RIGHT_ANKLE_DA;
