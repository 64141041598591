const US_TESTIS_DA = `ULTRASOUND TESTIS

Both testes are normal in size and show normal homogenous echotexture. No intratesticular lesion seen. Both epididymis are normal in appearance and not abnormally thickened. There is no hydrocele seen.

IMPRESSION:

Normal examination.      

`

export default US_TESTIS_DA;
