const HSG_DA = `HSG

The uterine cavity outline normally with contrast. No intrauterine filling defect seen.

Both uterine tubes outline  normally with contrast and show bilateral free intraperitoneal  spillage.

IMPRESSION:

Normal examination.

`

export default HSG_DA;
