const MRI_LUMBAR_SPINE_II_DA = `MRI LUMBAR SPINE
3.5 mm sagittal T1 and T2 were acquired through the lumbar spine. 3.5 mm axial T2 were acquired through the lumbar disc spaces.

The lumbar vertebra show normal bony alignment. Anterior osteophytes seen consistent with lumbar spondylosis with degenerative marrow changes seen in L2, L3 and L4 vertebral bodies Disc degenerations and disc space reductions seen at L2/L3 and L3/L4.

Large left postero lateral disc prolapse seen at L5/S1 causing bilateral foraminal and left lateral recess stenosis.

Mild diffuse disc prolapse seen at L2/L3, L3/L4 and L4/L5 causing focal thecal sac indentation and bilateral foraminal stenosis. 

Mild disc prolapse seen at T12/L1 and L1/L2 causing mild thecal sac indentation and mild bilateral foraminal stenosis.

Facet joints appear normal.

IMPRESSION:

1. Lumbar spondylosis and degenerative lumbar disc disease with degenerative marrow changes.
2.  Large left postero lateral disc prolapse at L5/S1 causing bilateral foraminal and left lateral recess stenosis.
3. Mild diffuse disc prolapse at L2/L3, L3/L4 and L4/L5 causing focal thecal sac indentation and bilateral foraminal stenosis. 
4. Mild disc prolapse seen at T12/L1 and L1/L2 causing mild thecal sac indentation and mild bilateral foraminal stenosis.

`

export default MRI_LUMBAR_SPINE_II_DA;
