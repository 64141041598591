const MRI_BRAIN_AND_MRA = `MRI BRAIN AND MRA

The images are partially degraded by motion / presence of metallic artefact (dental braces).

Findings:
A few tiny T2/FLAIR hyperintensities scattered in bilateral cerebral white matters. No restricted diffusion or perilesional edema seen. 

There is background chronic ischaemic cerebral white matter lesions seen in bilateral cerebral hemispheres.
Scattered small T2/FLAIR hyperintense foci with no restricted diffusion at bilateral periventricular in both frontal and parietal lobes.
A few small T2 hyperintense foci are seen at left frontal periventricular white matter with no restricted DWI.
Slightly prominent CSF spaces and sulci in keeping with age related cerebral atrophy.

The cerebral hemisphere shows normal grey-white matter signal intensity.
No focal brain parenchymal mass lesion or intracranial haemorrhage.
No hyperintense foci on T2/FLAIR with restricted diffusion seen.
No intracranial haemorrhage or acute infarct changes are seen.

Noted inhomogenous DWI and ADC map signal likely due to technical factors, most prominent at right insular cortex. However, no corresponding high T2/FLAIR signal is seen in the same region. No other focal abnormal signal intensity is seen.

Normal appearance of the basal ganglia, internal capsule, corpus callosum and thalamus.
The brainstem and cerebellum show normal configuration and signal characteristics.

The sella pituitary and parasellar regions appear normal, no focal lesion.

The cerebral ventricles are of normal size and symmetrical. The basal cisterns are not effaced. 
No signs of increased intracranial pressure. No midline shift.

The cerebellopontine angle area appears symmetrical. No obvious focal lesion seen.

Following contrast, there is no focal enhancing lesion or abnormal leptomeningeal enhancement seen.

The vertebrobasilar, internal carotid, anterior, middle and posterior cerebral arteries show normal signal flow and normal in caliber and appearance. No evidence of arterial-stenosis, aneurysm or vascular malformation seen. 

No significant mucosal thickening seen in the paranasal sinuses.
Mucosal thickening seen in the maxillary and ethmoid sinuses bilaterally.
No obvious fluid signal within the right and left mastoid air cells.

IMPRESSIONS:
1. Chronic white matter microvascular ischemic disease changes.
2. No intracranial mass lesion, bleed or acute infarct seen.
3. Sinusitis changes involving .
Please correlate clinically.
 
Few small T2 hyperintense foci seen at left frontal periventricular white matter are non-specific and may represent early demyelination.

Inhomogenous DWI and ADC map signal. These are likely due to technical factors and less likely due to acute infarcts.
 

IAM: Both VII and VIII nerves complexes are normal. The internal acoustic meatus has normal width. No IAM or cerebellopontine angle lesion seen.
`

export default MRI_BRAIN_AND_MRA;
