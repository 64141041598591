const MRI_LUMBAR_SPINE_I_DA = `MRI LUMBAR SPINE
3.5 mm sagittal T1 and T2 were acquired through the lumbar spine. 3.5 mm axial T2 were acquired through the lumbar disc spaces.
There is grade 1 spondylolisthesis seen at L4/L5 secondary to facet joint arthrosis with  ligamentum flavum hypertrophy  and mild disc bulge causing focal thecal sac indentation significant central spinal canal stenosis and bilateral foraminal stenosis.
Mild lateral disc bulge seen at L2/L3 and L3/L4 causing bilateral foraminal stenosis. No ligamentum flavum hypertrophy at these levels. 
The rest of the spinal canal and lateral neuroforamina appear normal.
The rest of the facet joints appear normal.
Anterior osteophytes seen consistent with lumbar spondylosis. Disc degenerations seen at L1/L2, L2/L3 and L5/S1. 
Bi concave endplates of the lumbar spine seen suggestive of underlying osteopenia. 
No wedge compression fracture or marrow oedema/bony contusion of the lumbar vertebra seen.
IMPRESSION:
1. Significant spinal canal stenosis L4/L5 secondary to grade 1 degenerative spondylolisthesis, ligamentum flavum hypertrophy and mild disc bulge
2. Mild lateral disc bulge at L2/L3 and L3/L4 causing bilateral foraminal stenosis. N
3. No recent compression fracture of lumber vertebra noted
`

export default MRI_LUMBAR_SPINE_I_DA;
