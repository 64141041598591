const CECT_BRAIN = `CT BRAIN (PLAIN) / CECT BRAIN

Findings:

There are periventricular white matter hypodensities bilaterally, in keeping with chronic microvascular ischaemic changes.
 
Periventricular white matter hypodensities in both frontal and parietal lobes.
Prominent CSF spaces and sulci in keeping with age related cerebral atrophy.

A small well-circumscribed hypodensity of fluid/CSF attenuation seen adjacent to the left lentiform nucleus / the lower third of basal ganglia between left lentiform nucleus and left side of midbrain. No surrounding mass effect is seen. → Small hypodensity adjacent to the left lentiform nucleus likely to represent prominent perivascular space. Differential includes lacunar infarcts. 
 
No area of loss of grey-white matter differentiation. 
Normal grey-white matter differentiation.
No focal brain lesion or intracranial bleed seen.
The sulci and basal cisterns are not effaced.
The ventricles are normal and not dilated.
The brain stem appears normal.
No midline shift is seen.

No focal enhancing brain parenchymal lesion or abnormal leptomeningeal enhancement is seen.
 
Noted presence of persistent cavum septum pellucidum / cavum vergae (normal variant).
 
No significant mucosal thickening in the visualised paranasal sinuses.
Mild mucosal thickening seen in the ethmoid and maxillary sinuses.

No focal skull vault lesion or fracture is seen.
 
IMPRESSION:
No intracranial bleed or mass lesion.
Please correlate clinically.
 
IMPRESSION:
Chronic white matter microvascular ischemic disease changes.
No obvious acute infarct or bleed is seen.
No evidence of increased intracranial pressure.
Please correlate clinically.
 
 
ORBIT
Both orbits are normal. No nodule or mass lesions seen.
Both optic nerves are well seen, no calcifications or focal lesions. 
No obvious perineural inflammation at both retrobulbar optic nerves.
The extraocular muscles are symmetrical and normal.

`

export default CECT_BRAIN;
