const CT_URUGRAM_DA = `CT UROGRAM: 

0.5 x 64MDCT was done through the urinary bladder without i.v contrast. 

Both kidneys are normal in size, right kidney measures 9.4 cm and left kidney 9.5cm and show normal cortical thickness. There is no radio-opaque stone seen in the right and left kidneys, right and left ureters or urinary bladder. No hydronephrosis or hydroureter seen. Urinary bladder appear normal.

IMPRESSION:  

Normal examination. 
`

export default CT_URUGRAM_DA;
