const AXR_DA = `AXR:

Faecal loading noted in the colon
Bowel gas pattern otherwise appear within normal limits .
Multiple phlebolths seen in left side of pelvis 
No obvious urinary tract calcification seen

`

export default AXR_DA;
