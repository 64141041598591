const MRI_RIGHT_KNEE_DA = `MRI OF THE RIGHT KNEE

3 mm sagittal T1, T2 and PD FAT SAT and 3 mm coronal T2 and axial PASTA  were acquired through the right knee.

The medial and lateral  menisci appear normal showing no evidence  of meniscal injury or tear. 

The anterior  and posterior cruciate, medial  and lateral collateral ligaments appear intact and  show normal signal intensity.

The articular cartilage appear preserved and there is no joint effusion seen. No bony  or marrow abnormality seen.

IMPRESSION:

Normal examination.
`

export default MRI_RIGHT_KNEE_DA;
