const US_PAEDS_ABDOMEN_AND_PELVIS = `(PAEDS)
ULTRASOUND ABDOMEN & PELVIS
 
The liver is normal in size and echotexture. The margin is smooth. No focal lesion.

The gallbladder is normal. The gallbladder wall is not thickened. The intrahepatic ducts and common bile duct are not dilated.

The pancreas and spleen are normal. 

Both kidneys are of normal echopattern, size and shape. No hydronephrosis.

Normal urinary bladder. No debris seen within the bladder. 

A few reactive mesenteric nodes seen at the right side of the abdomen measuring 0.95 x 0.48 cm and 1.12 x 0.52 cm. No obvious mass lesion or free fluid in the abdomen or pelvis.
 
IMPRESSION:
No abnormality detected.
A few reactive mesenteric nodes. 
Differential includes mesenteric adenitis.
Please correlate clinically.
`

export default US_PAEDS_ABDOMEN_AND_PELVIS;
