const BILATERAL_MAMMOGRAM_II_DA = `BILATERAL MAMMOGRAM:

Both breast parenchyma appear symmetrical.  No discrete mass lesion , suspicious clustered microcalcification or parenchymal deformity seen.

No significant axillary lymphadenopathy seen.

IMPRESSION;   

No significant focal abnormality or sign of malignancy seen .   

BIRADS CATEGORY 1. Routine screening ( 2yearly) recommended. 

DISCLAMER : 

The mammogram may miss  up to 5-15 % of cancers in the breast . The management of the patient should be based on clinical grounds .

`

export default BILATERAL_MAMMOGRAM_II_DA;
