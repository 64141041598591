const US_KUB = `ULTRASOUND KUB
 
Both kidneys show slightly irregular contour with mild increased renal parenchymal echogenicity.
Both kidneys are of normal echopattern, size and shape.
The bipolar length of the right kidney is cm with cm cortical thickness.
The bipolar length of the left kidney is cm with cm cortical thickness.
A few small simple cortical cysts seen in both kidneys measure 7 - 10 mm.
No obvious stone or renal lesion seen. No hydronephrosis.
 
Normal / Partially filled urinary bladder. No stone or debris within. The wall is not thickened.
The pre-micturition bladder volume is mls.
The post-micturition bladder volume is mls.
The prostate gland volume is about mls.
 
IMPRESSION:
Bilateral early renal parenchymal disease changes. No hydronephrosis.
Enlarged prostate with chronic bladder outlet obstruction changes.
Bilateral simple renal cortical cysts.
No abnormality detected.
Please correlate clinically.
`

export default US_KUB;
