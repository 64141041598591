const LEFT_HAND_DA = `LEFT HAND

Clinical Information: For bone age.

Chronological  age = 12 years and 3 months.

Bone age between 9 and 10 years.

Reference Greulich and Pyle radiographic atlas of skeletal development.

IMPRESSION:

Delayed bone age.

`

export default LEFT_HAND_DA;
