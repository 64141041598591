const BONE_MINERAL_DENSITY_DA = `BONE MINERAL DENSITY:

SPINE:
The BMD of the spine is     cm/gm sq with  a T score of   
	
HIP:
The BMD of the neck of femur is    cm/gm sq with a T score of 

IMPRESSION;  

Normal bone mineral density of the spine and hip.

`

export default BONE_MINERAL_DENSITY_DA;
