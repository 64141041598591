const US_SCROTUM = `ULTRASOUND SCROTUM

The right and left testes are normal in size and echotexture.
Both testes are seen located within the scrotal sac.
Normal testes vascularity. No focal lesions.
Both epididymis are not thickened.
No inguinal hernia or hydrocele.
No varicocele seen.

IMPRESSION: No testicular/scrotal swelling or lesions. Please correlate clinically.
`

export default US_SCROTUM;
