const CECT_NECK_THORAX_ABDOMEN_PELVIS = `CECT NECK, THORAX, ABDOMEN AND PELVIS
 
Images are partially degraded by breathing artefacts.

Findings:
No enlarged cervical, supraclavicular, axillary or mediastinal lymph nodes seen.
 
The post nasal space, larynx, glottic and infraglottic regions are normal. No focal lesion or collection is seen.

Thyroid, parotid and submandibular glands are normal. No abnormal calcification or focal lesion is seen.

No significant mucosal thickening in the paranasal sinuses.
The right and left mastoid air cells are clear.
 
Both lung fields are clear. No lung nodules or consolidation are seen.
No bronchiectatic or chronic lung changes are seen.
 
No pericardial or pleural effusion noted.
No mediastinal or hilar lymphadenopathy.

Heart size appears within the normal limit. Major mediastinal vessels appear normal.

The liver is of normal size. The margins are smooth. No focal lesion is seen.
 
The liver is normal in size and shows homogenous hypodensity compared to the spleen. The margin is smooth. No focal liver lesion.
 
Well/partially distended gallbladder. No wall edema or obvious radiopaque gallstones seen.
The intra and extrahepatic biliary ducts are not dilated.
 
Well distended gallbladder with a few opaque stones within measures 2 mm each. No wall edema or pericholecystic streakiness/free fluid is seen. The intra and extrahepatic biliary ducts are not dilated.
 
Spleen, pancreas and adrenals are normal. No focal lesion.
 
Both kidneys are of normal size and shape. Both kidneys show a normal symmetrical enhancement pattern. No focal lesion, stone or hydronephrosis seen.
 
The gastroesophageal junction is slightly above the diaphragmatic hiatus mildly thickened.
No small or large bowel related mass lesions. No diverticuli or wall thickening.
Appendix measures 5 mm. No streakiness of surrounding fat.
 
The urinary bladder is normal. Prostate gland is not enlarged.
 
The uterus is anteverted / retroverted. No obvious focal lesion is seen. Both ovaries appear normal. No adnexal solid or cystic lesion seen.
 
No para-aortic or pelvic lymphadenopathy seen.
No free fluid in the abdomen or pelvis.
 
A few subcentimeter para-aortic nodes are seen. These are likely reactive in nature.
Small free fluid seen in the pelvis.
 
IMPRESSION:  
 
Please correlate clinically.

`

export default CECT_NECK_THORAX_ABDOMEN_PELVIS;
