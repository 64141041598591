const CXR_DA = `CXR:

Heart size normal.
Both lung fields appear clear.
No hilar or mediastinal abnormality seen.

IMPRESSION:

Normal examination

`

export default CXR_DA;
