const US_ABDOMEN_AND_PELVIS_DA = `ULTRASOUND ABDOMEN AND PELVIS:

The liver is not enlarged and has normal homogenous echotexture. No focal intrahepatic lesion seen. Gallbladder appear normal. No gallstone seen. Bile ducts are not dilated, CBD measures   mm.

Spleen, pancreas and both kidneys appear normal, right kidney measures    cm and left kidney    cm

Urinary bladder and prostate gland appear normal. 

IMPRESSION: 

Normal examination.

`

export default US_ABDOMEN_AND_PELVIS_DA;
